* {

      
}
@font-face {
    font-family: 'Marker';
    font-style: normal;
    font-weight: normal;
    src: local('PermanentMarker.ttf'),
    url('./Fonts/PermanentMarker.ttf') format('truetype');
  }
  @font-face {
    font-family: 'Modak';
    font-style: normal;
    font-weight: normal;
    src: local('Modak.ttf'),
    url('./Fonts/Modak.ttf') format('truetype');
  }
  @font-face {
    font-family: 'Dosis';
    font-style: normal;
    font-weight: normal;
    src: local('Dosis.ttf'),
    url('./Fonts/Dosis.ttf') format('truetype');
  }